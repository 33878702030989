import React from 'react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import Input from "../components/common/Input"
import Textarea from "../components/common/TextArea"
import Button from '../utils/Button';
import H1 from '../utils/H1';
import H3 from '../utils/H3';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { AiOutlineCheckCircle } from "@react-icons/all-files/ai/AiOutlineCheckCircle";
const defaultValues = {
    email: "",
};
const ErrorMessage = ({ children }) => (
    <div className="text-base text-red-400 font-lato mt-2" role="alert">
        {children}
    </div>
);
export default function ContactForm() {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(false);
    const onError = (errors, e) => {
        console.log(errors, e)
    };
    const onSubmit = async (data, event) => {
        event.preventDefault();
        console.log(data)
        addToMailchimp(data.email, { NAME: data.name, SUBJECT: data.subject, MESSAGE: data.message })
            .then(data => {
                setStatus(true);
            })
            .catch((error) => {
                setStatus(false);
                setError("Sorry, there was an error submitting. Please try again later.")
            })
        reset()
    }

    return (
        status ? (
            <div className="w-full h-auto flex flex-col justify-center items-center relative m-auto p-12 left-0 top-0 z-10">
                <div className="relative w-full h-full flex flex-col items-center justify-center cursor-pointer transition-all duration-500">
                    <AiOutlineCheckCircle size={60} color={"#4EB693"} className="mb-4"></AiOutlineCheckCircle>
                    <H1 className="mb-8 text-blue">
                        Thank you!
                    </H1>
                    <H3 className="text-textGray font-bold text-center mb-12">
                        Your message has been received. <br></br>Please check your email for further communication.
                    </H3>
                    <Button type={"internalLink"} to="/about-us" className="bg-blue text-white">About Us</Button>
                </div>
            </div>
        ) : (
            <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="flex flex-col justify-center w-full mt-8"
                noValidate
            >
                <Input
                    labelKey="Name"
                    type="text"
                    variant="solid"
                    {...register("name", {
                        required: "Your Name (required)",
                    })}
                    errorKey={errors.name?.message}
                />
                <Input
                    labelKey="Email"
                    type="email"
                    variant="solid"
                    className="mb-4"
                    {...register("email", {
                        required: `${("Please provide your email address")}`,
                        pattern: {
                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: ("Please provide a valid email address"),
                        },
                    })}
                    errorKey={errors.email?.message}
                />
                {/* <Input
                labelKey="Phone"
                type="tel"
                variant="solid"
                className="mb-4"
                {...register("phone", {
                    required: "Please enter your phone number",
                    pattern: {
                        value: /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
                        message: ("Please provide a valid Phone Number"),
                    },
                })}
                errorKey={errors.phone?.message}
            /> */}
                <Input
                    labelKey="Subject"
                    type="text"
                    variant="solid"
                    {...register("subject", {
                        required: "Your Subject (required)",
                    })}
                    errorKey={errors.subject?.message}
                />
                <Textarea
                    labelKey="Message"
                    type="text"
                    variant="solid"
                    {...register("message", {
                        required: "Your Message (required)",
                    })}
                    errorKey={errors.message?.message}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <Button type="submit" variant={"normal"} className={"bg-blue text-white mt-8 ml-auto mr-auto px-12"}>
                    {("Submit")}
                </Button>
            </form>
        )
    )
}
