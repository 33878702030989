import React from "react";

const classes = {
    root:
        "py-2 px-4 md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input text-xs lg:text-sm font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out",
    normal:
        "bg-gray-100 border-gray-300 focus:shadow focus:bg-white focus:border-primary",
    solid:
        "bg-lightblue border-blue focus:outline-none focus:border-heading h-11 md:h-12",
    outline: "border-gray-300 focus:border-primary",
    shadow: "focus:shadow",
};
const TextArea = React.forwardRef(
    (
        {
            className = "block",
            labelKey,
            name,
            errorKey,
            placeholderKey,
            variant = "normal",
            shadow = false,
            type = "text",
            inputClassName,
            ...rest
        },
        ref
    ) => {
        const rootClassName = classes.root + " " + (variant === "normal" ? classes.normal : variant === "solid" ? classes.solid : variant === "outline" ? classes.outline : "") + " focus:shadow " + inputClassName;

        return (
            <div className={className}>
                {labelKey && (
                    <label
                        htmlFor={name}
                        className="block text-black font-normal font-lato text-base leading-none mb-3 cursor-pointer"
                    >
                        {labelKey}
                    </label>
                )}
                <textarea
                    id={name}
                    name={name}
                    type={type}
                    ref={ref}
                    // @ts-ignore
                    placeholder={placeholderKey}
                    className="bg-lightblue text-black font-questrial w-full border-blue rounded-lg border-1 focus:outline-none h-40 md:h-40 pl-2"
                    autoComplete="off"
                    spellCheck="false"
                    aria-invalid={errorKey ? "true" : "false"}
                    {...rest}
                />
                {errorKey && <p className="my-2 text-xs text-red-500">{errorKey}</p>}
            </div>
        );
    }
);

export default TextArea;
